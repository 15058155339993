@import "../../../../colors";

.user-info-container {
  max-width: 350px;
  display: flex;
  .form-container {
    -webkit-animation: slide-in-blurred-bottom 0.6s
      cubic-bezier(0.23, 1, 0.32, 1) both;
    animation: slide-in-blurred-bottom 0.4s cubic-bezier(0.23, 1, 0.32, 1) both;
    .introduction {
      h1 {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 10px;
      }
      p {
        font-size: 0.9rem;
        color: rgb(48, 48, 48);
        font-weight: 300;
        margin-bottom: 20px;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .input-group {
        display: flex;
        flex-direction: column;
        transition: all 0.2s;

        .split-inputs {
          display: flex;
          gap: 10px;

          input {
            text-transform: capitalize;
          }
        }

        input {
          width: 100%;
          border: 1px solid $lightGrey;
          border-radius: 5px;
          padding: 5px;
          letter-spacing: 1.5px;

          &:focus {
            outline: none;
            border: 1px solid $main;
          }
        }
      }

      .phone-input {
        display: flex;
        flex-direction: column;
        width: 100%;
        .inputs {
          gap: 10px;
          display: flex;
          .country-phone-codes {
            width: 50px;
            height: fit-content;
          }
          .phone-9-digits {
            width: 100%;
          }
        }
      }
      .error {
        border: 1px solid red !important;
      }
      .submit-button {
        width: 100%;
        background-color: $main;
        align-self: center;
        color: white;
        border: none;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        font-weight: bolder;
        font-size: 1.1rem;
        transition: all 0.4s;

        &:disabled {
          background-color: $lightGrey;
          color: white;
          cursor: not-allowed;
          opacity: 0.5;
        }

        &:hover:not(:disabled) {
          box-shadow: 0px 0px 7px 2px $main;
        }
      }
    }

    .small-after-submit {
      display: flex;
      p {
        height: fit-content;
        padding: 10px;
        transition: all 0.2s;
        border-radius: 15px;
        &:hover {
          cursor: pointer;
          background-color: rgba(65, 14, 14, 0.123);
        }
      }
    }
  }
  .short-data {
    width: 100%;
    cursor: pointer;
    .data-row {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 30px;
      margin-bottom: 20px;

      .data {
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
        .title {
          font-weight: 600;
        }
        .name {
          text-transform: capitalize;
        }
      }
    }
  }

  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      -webkit-filter: blur(20px);
      filter: blur(20px);
      opacity: 0;
    }
    100% {
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 360px) {
    width: 95%;

    .form-container {
      form {
        .input-group {
          width: 100%;
        }
        .split-inputs {
          flex-wrap: wrap;
          width: 100%;

          input {
            width: 100%;
          }
        }
      }
    }
  }
}
