@import "./../../../colors";

.page-footer-container {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 20px;
  flex-wrap: wrap;
  background-color: $backgroundOfFooter;

  .navigation {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .navigation__link {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 10px 0px;
    justify-content: center;
  }
}
