@import "../../../../../colors";

.confirmation-email-container {
  margin-top: 20px;
  text-align: center;
  h1 {
    font-size: 2.5rem;
  }
  p {
    font-size: 1rem;
    color: grey;

    span {
      color: $main;
      font-weight: bolder;
    }
  }
  form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      padding: 5px;
      text-align: center;
      font-size: 2.5rem;
      border: 1px solid rgb(141, 141, 141);
      border-radius: 10px;
      width: 150px;

      &:focus {
        outline: 1px solid rgb(141, 141, 141);
      }
    }
    button {
      margin-top: 70px;
      padding: 10px 20px;
      border-radius: 10px;
      min-width: 150px;
      background-color: $mainDarker;
      color: white;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: $main;
      }

      &:disabled {
        background-color: grey;
        opacity: 0.6;
        cursor: not-allowed;
      }

      &:not(:disabled) {
        &:active {
          scale: 0.95;
        }
      }
    }

    .message-information {
      margin-top: 20px;
      font-size: 1rem;
      color: $green;
    }
  }
}
