@import "../../colors";
.reservation-container {
  background-color: $backgroundOfPage;
  .Calendar {
    margin-right: auto;
    margin-left: auto;
  }

  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    min-height: 800px !important;

    h1 {
      font-size: 2.4375rem;
    }

    .image-big {
      display: block;
      max-width: 95% !important;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0 13px 43px rgb(37 46 89 / 23%);
    }
  }

  .informations-about-apartament {
    margin-top: 100px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    padding-right: 150px;
    padding-left: 150px;
    padding-bottom: 40px;
    justify-content: center;

    .informations {
      width: 50%;
      position: relative;

      h2 {
        font-family: "Noto Serif", serif;
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 50px;
      }

      p {
        word-spacing: 3px;
        word-break: keep-all;
        text-align: justify;
        white-space: pre-wrap;
        line-height: 1.75;
        margin-bottom: 1.5em;
        color: #5f6060;
      }

      .gogole-maps-button {
        display: flex;
        padding: 10px;
        margin-top: 10px;
        color: white;
        font-weight: 600;
        background-color: $main;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        text-align: center;

        img {
          height: 35px;
          width: 25px;
        }

        &:hover {
          background-color: $mainDarker;
          color: white;
        }
      }
    }

    .reservate-box-div {
      position: sticky;
      top: 100px;
      align-self: flex-start;
      background-color: white;
      border-radius: 5px;
      margin-left: auto;
      -webkit-box-shadow: 0 13px 23px 0 rgba(37, 46, 89, 0.1);
      -moz-box-shadow: 0 13px 23px 0 rgba(37, 46, 89, 0.1);
      box-shadow: 0 13px 23px 0 rgba(37, 46, 89, 0.1);
      padding: 50px;
      .info-box {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        h2 {
          font-size: 1.2rem;
          max-width: 400px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        .info-container {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          justify-content: center;

          p {
            svg {
              padding-right: 10px;
            }
          }
          p.phone {
            svg {
              color: rgb(0, 192, 0);
            }
          }
          p.email {
            svg {
              color: rgb(85, 85, 172);
            }
          }
        }
      }
      .apartment-price-one-day-rent {
        display: flex;
        padding-left: 10px;
        margin-bottom: 20px;
        font-size: 1.9375rem;
        font-weight: 500;
        .currency-span {
          font-size: 1.2rem;
          width: fit-content;
          align-self: flex-start;
          margin-right: 6px;
        }
        .days-span {
          align-self: center;
          font-size: 0.875rem;
          color: grey;
        }
      }
      .error-message {
        color: rgb(255, 89, 89);
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .make-reservation-button {
        margin-top: 20px;
        width: 100%;
        border-radius: 30px;
        background-color: $main;
        border: none;
        padding: 10px;
        font-size: 15px;
        color: white;
        transition: 0.2s ease-in-out;
        font-weight: bolder;

        &:disabled {
          opacity: 0.5;
        }

        &:hover {
          background-color: $mainDarker;
        }
      }
    }
    .more-information-div {
      margin-bottom: 30px;
      .map-div {
        p {
          font-size: 1.1rem;
          width: fit-content;
          font-weight: 600;
          color: grey;
          span {
            cursor: pointer;
            color: black;

            &:hover {
              color: rgb(70, 70, 70);
            }
          }
        }
      }
    }
    .page-navigation-div {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      flex-wrap: wrap;
      padding-bottom: 10px;
      border-bottom: 2px solid #c2c2c2;

      button {
        user-select: none;
        font-weight: 600;
        font-size: 1rem;
        transition: 0.1s;

        &:hover:not(.selected) {
          color: grey;
        }
        &:active:not(.selected) {
          scale: 0.9;
        }
      }

      .selected {
        pointer-events: none;
        border-bottom: 3px solid $main;
      }
    }
  }

  .selected-range {
    background-color: $mainDarker !important;
  }

  .between-selected-range {
    color: white !important;
    background-color: $main !important;
  }

  .today-callendar {
    pointer-events: none;
    color: white !important;
    background-color: #3c4a92 !important;
  }

  @media only screen and (max-width: 1150px) {
    & {
      padding-left: 25px;
      padding-right: 25px;
    }
    .reservate-box-div {
      margin-left: 0 !important;
    }

    .informations-about-apartament {
      padding-left: 0;
      padding-right: 0;

      .informations {
        width: 80%;
      }
    }
    .header-div {
      min-height: auto !important;
    }
  }

  @media screen and (max-width: 450px) {
    & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
