@import '../../../../colors';

.modal-information-container {
  p {
    padding: 30px;
    line-height: 1.7;
    font-size: large;

    a {
      font-weight: bold;
      border-bottom: 2px solid $main;
    }
  }
}
