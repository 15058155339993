@import "../../colors";
.cancel-request-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 20px 0;
    font-size: 2rem;
    font-weight: bolder;
  }

  .loading-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 1rem;
      font-weight: 600;
      color: rgb(0, 0, 65);
    }
  }

  .cancel-completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 1rem;
      font-weight: 600;
      color: $green;
    }
    .button-back {
      margin-top: 20px;
      width: 100%;
      border-radius: 30px;
      background-color: $main;
      border: none;
      padding: 10px;
      font-size: 15px;
      text-align: center;
      color: white;
      transition: 0.2s ease-in-out;
      font-weight: bolder;

      &:disabled {
        opacity: 0.5;
      }

      &:hover {
        background-color: $mainDarker;
      }
    }
  }

  .cancel-failed {
    p {
      font-size: 1rem;
      font-weight: 600;
      color: $red;
    }
  }
}
