@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #ffffff !important;
  font-family: "Open Sans", sans-serif !important;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  /* macOs Scrollbar */
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  background: #eff6fb;
}
body::-webkit-scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 15px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #95a5a6;
}
body::-webkit-scrollbar-thumb:active {
  background: #7f8c8d;
}
body::-webkit-scrollbar-thumb:window-inactive {
  background: #bdc3c7;
}
body::-webkit-scrollbar-thumb:window-inactive:hover {
  background: #95a5a6;
}
body::-webkit-scrollbar-thumb:window-inactive:active {
  background: #7f8c8d;
}
body::-webkit-scrollbar-button {
  display: none;
}
p {
  font-family: "Open Sans", sans-serif !important;
}

.app {
  position: relative;
  background-color: #fff;
}
#root {
  position: relative;
  min-height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.container-with-header {
  padding-top: 82px;
  overflow-x: hidden;
}

// make all fonts smaller on mobbile
