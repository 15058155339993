@import "../../colors";

.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  gap: 5px;
  .line {
    height: 100%;
    min-height: 100px;
    width: 1px;
    background-color: rgb(201, 201, 201);
  }
  .icon-container {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      padding: 10px;
      border-radius: 50%;
    }
  }

  .correct {
    padding: 10px;
    background-color: $green;
    color: white;
  }
}
