@import "../../../../colors";

.mail-confirmation-container {
  display: flex;
  width: 300px;

  .mail-confirmation {
    animation: slide-in-blurred-bottom 0.4s cubic-bezier(0.23, 1, 0.32, 1) both;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    .introduction {
      h1 {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 10px;
      }
      p {
        font-size: 0.9rem;
        color: rgb(48, 48, 48);
        font-weight: 300;
        margin-bottom: 20px;
      }
    }

    input {
      padding: 5px;
      text-align: center;
      font-size: 2.5rem;
      border: 1px solid rgb(141, 141, 141);
      border-radius: 10px;
      width: 150px;

      &:focus {
        outline: 1px solid rgb(141, 141, 141);
      }
    }
    button {
      margin-top: 70px;
      padding: 10px 20px;
      border-radius: 10px;
      min-width: 150px;
      background-color: $mainDarker;
      color: white;
      transition: all 0.2s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &:hover {
        cursor: pointer;
        background-color: $main;
      }

      &:disabled {
        background-color: grey;
        opacity: 0.6;
        cursor: not-allowed;
      }

      &:not(:disabled) {
        &:active {
          scale: 0.95;
        }
      }
    }
  }
  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      -webkit-filter: blur(20px);
      filter: blur(20px);
      opacity: 0;
    }
    100% {
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }
}
