@import "../../colors";

.index-container {
  background-color: $backgroundOfPage;
  padding-bottom: 100px;
  .main-title-div {
    position: relative;
    height: 70dvh;
    background: url("../../assets/images/tajao-glowne.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.295);
    }
    .title-div {
      width: max-content;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;

      h1 {
        font-size: 3rem;
        white-space: wrap;
        font-weight: bolder;
        word-spacing: 10px;
        text-align: center;
        margin-bottom: 0;
        animation: puff-in-center 0.4s ease-in-out both;

        span {
          font-weight: 900;
          color: rgb(148, 227, 247);
        }
      }
      p {
        font-size: 1.35rem;
        font-weight: bolder;
        text-align: center;
        margin-top: 0;
        animation: tracking-in-expand 0.4s ease-out 0.2s both;
      }

      .about-button {
        display: block;
        margin-top: 70px;
        background-color: rgb(130, 200, 218);
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        font-size: 1.1rem;
        font-weight: 600;
        text-align: center;
        border-radius: 60px;
        transition: all 0.3s;
        animation: slide-in-bottom 0.5s ease-out 0.5s both;

        &:active {
          scale: 0.95;
        }

        &:hover {
          background-color: rgb(96, 151, 165);
        }
      }
    }
  }
  .margin-div {
    margin-top: 10vw;
    margin-bottom: 100px;
    .description-about-apartments {
      margin-left: 7vw;
      margin-right: 7vw;
      display: flex;
      justify-content: center;
      gap: 30px;

      gap: 50px;
      .description {
        max-width: 600px;
        h2 {
          font-size: 30px;
          font-weight: 600;
        }
        h2::first-letter {
          font-size: 100px;
          padding-right: 5px;
        }
        p {
          max-width: inherit;
          display: block;
          text-overflow: clip;
          white-space: pre-wrap;
          word-wrap: keep-all;
          min-width: 200px;
          line-height: 2rem;
          max-width: 80vw;
          color: grey;
          font-size: 1.1rem;

          span {
            font-weight: 600;
            color: $mainDarker;
          }
        }
      }
      .images-div {
        display: flex;
        height: fit-content;
        gap: 10px;
        flex-wrap: wrap;
        align-self: center;
        justify-content: flex-end;
        user-select: none;
        img {
          width: 24vw;
          height: 14vw;
          border-radius: 15px;
          justify-self: flex-end;
          &:nth-child(3) {
            justify-content: center;
          }
        }
      }
    }

    .what-we-offer {
      padding-left: 5vw;
      padding-right: 5vw;
      padding-top: 50px;
      padding-bottom: 50px;
      background-color: rgb(240, 240, 240);

      h2 {
        font-size: 30px;
        font-weight: 800;
        font-family: "Open Sans", sans-serif;
        text-align: center;
        margin-bottom: 50px;
      }

      .offer-div {
        display: flex;
        justify-content: space-around;
        gap: 50px;
        flex-wrap: wrap;
        .offer {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          .offer-image {
            width: 75px;
            height: 75px;
            padding: 20px;
            border-radius: 50%;
            background-color: $main;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h3 {
            font-size: 1.5rem;
            font-weight: 600;
            text-align: center;
          }
          p {
            display: block;
            max-width: 320px;
            font-size: 1.1rem;
            text-align: center;
            color: grey;
          }
        }
      }
    }

    .gallery {
      h2 {
        text-align: center;
        font-size: 40px;
        font-weight: 800;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 50px;
        &:after {
          content: "";
          display: block;
          width: 100px;
          height: 5px;
          background-color: $main;
          margin: 0 auto;
          margin-top: 10px;
        }

        &:before {
          content: "";
          display: block;
          width: 100px;
          height: 5px;
          background-color: $main;
          margin: 0 auto;
          margin-top: 10px;
        }
      }

      .gallery-div {
        max-width: 1100px;
        margin: 0 auto;

        .apartment-iamges-div {
          margin-top: 50px;
        }
        h3 {
          cursor: default;
          width: fit-content;
          font-size: 1.5rem;
          font-weight: 600;
          font-style: italic;
          margin-bottom: 50px;
          &:after {
            transition: width 0.3s;
            content: "";
            display: block;
            width: 50px;
            height: 5px;
            background-color: $main;
            margin-top: 10px;
          }

          &:hover {
            &:after {
              width: 100px;
            }
          }
        }
      }
    }
  }

  ///////////////////////////  MEDIA QUERIES ///////////////////////////

  @media only screen and (max-width: 1600px) {
    .main-title-div {
      .title-div {
        h1 {
          font-size: 2.8rem;
        }
        p {
          font-size: 1.3rem;
        }
      }
    }
    .description-about-apartments {
      .description {
        h2 {
          font-size: 28px;
        }
        p {
          font-size: 1.25rem !important;
          line-height: 2.5rem !important;
        }
      }
      .images-div {
        justify-content: center !important;
        img {
          width: 34vw !important;
          height: 17vw !important;
        }
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .main-title-div {
      .title-div {
        h1 {
          font-size: 2.5rem;
        }
        p {
          font-size: 1.2rem;
        }
      }
    }
    .description-about-apartments {
      .description {
        h2 {
          font-size: 25px;
        }
        p {
          font-size: 1.25rem !important;
          line-height: 2.5rem !important;
        }
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .main-title-div {
      .title-div {
        h1 {
          font-size: 2rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
    .description-about-apartments {
      flex-wrap: wrap;
      justify-content: center !important;
      .description {
        h2 {
          font-size: 20px;
        }
        p {
          text-align: left;
          font-size: 1.15rem !important;
          line-height: 2rem !important;
        }
      }
      .images-div {
        img {
          width: 80vw !important;
          height: 50vw !important;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .main-title-div {
      .title-div {
        h1 {
          display: block;
          font-size: 1.5rem;
          width: 60vw;
          text-align: center;
          word-wrap: break-word;
          margin-left: auto;
          margin-right: auto;
        }
        p {
          font-size: 0.8rem;
        }
      }
    }
    .description-about-apartments {
      .description {
        h2 {
          text-align: center;
          font-size: 25px !important;

          &:first-letter {
            font-size: 60px !important;
          }
        }
        p {
          font-size: 1rem !important;
          line-height: 1.75rem !important;
        }
      }
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-6-13 10:54:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
  @-webkit-keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
      transform: scale(2);
      -webkit-filter: blur(4px);
      filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
      transform: scale(2);
      -webkit-filter: blur(4px);
      filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-6-13 10:56:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-6-13 11:0:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}
