@import "../../../colors";

.backdrop-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(240, 240, 240, 0.568);
  backdrop-filter: blur(5px);
  z-index: 100;
  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .custom-loader {
      width: 100px;
      height: 100px;
      border: 4px solid $main;
      display: grid;
      animation: h1 4s infinite linear;
    }
    .custom-loader::before,
    .custom-loader::after {
      content: "";
      grid-area: 1/1;
      margin: auto;
      width: 70.7%;
      height: 70.7%;
      border: 4px solid $main;
      animation: inherit;
    }
    .custom-loader::after {
      width: 50%;
      height: 50%;
      border: 4px solid $main;
      animation-duration: 1.25s;
    }
    @keyframes h1 {
      100% {
        transform: rotate(1turn);
      }
    }
  }
}
