$main: #82c8da;
$mainDarker: #70a8b6;
$goldDisabled: #c1b0868e;
$darkBlue: #283a96;
$lightBlue: #4d6bc4;
$red: #fd5353;
$lightRed: #ffbebe;
$redError: #ff6565;
$grey: #5f6060;
$green: #019601c9;
$backgroundOfPage: #f8f8f8;
$backgroundOfFooter: #f0f0f0;
$lightGrey: #b4b4b4;
