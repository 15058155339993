@import "../../colors";

.reservation-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  .number-of-reservation {
    font-size: 2.3rem;
  }
  .details-container {
    width: 100%;
    min-height: 750px;
    display: flex;

    .apartment-informations-container {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(231, 255, 246);
      .box {
        -webkit-animation: tilt-in-bottom-1 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: tilt-in-bottom-1 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        border-radius: 5px;
        .apartment-image {
          img {
            width: 350px;
            aspect-ratio: 3.5/2;
          }
        }

        .apartment-info {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-width: 350px;
          .info-group {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid rgb(231, 231, 231);
            h2 {
              font-size: 1rem;
              font-weight: 600;
              color: rgb(0, 0, 65);
            }
            .group-of-facilities {
              margin-top: 20px;
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              justify-content: center;
              user-select: none;
              .facility {
                border-radius: 100px;
                background-color: rgb(197, 218, 255);
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: space-between;
                padding: 2px 10px;

                svg {
                  color: $green;
                  font-weight: bolder;
                }
              }
            }
          }

          .facilities {
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }
    .reservation-details {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .container-with-info {
        margin-top: 30px;
        width: 50%;
        .reservation-info {
          margin-bottom: 30px;

          .title-datas:nth-child(1) {
            -webkit-animation: text-focus-in 0.2s
              cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
            animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
              both;
          }
          .title-datas:nth-child(2) {
            -webkit-animation: text-focus-in 0.2s
              cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.2s both;
            animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
              0.2s both;
          }
          .title-datas:nth-child(3) {
            -webkit-animation: text-focus-in 0.2s
              cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.4s both;
            animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
              0.4s both;
          }
          .title-datas:nth-child(4) {
            -webkit-animation: text-focus-in 0.2s
              cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.6s both;
            animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
              0.6s both;
          }
          .title-datas:nth-child(5) {
            -webkit-animation: text-focus-in 0.2s
              cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.8s both;
            animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
              0.8s both;
          }
          .tag {
            display: flex;
            gap: 5px;
            background-color: $lightBlue;
            color: white;
            font-weight: bolder;
            width: fit-content;
            padding: 5px;
            border-radius: 50px;
            margin-top: 20px;
            user-select: none;
          }
          .canceled {
            background-color: $red !important;
            color: $lightRed !important;
            font-size: 30px !important;
          }
          .icon {
            height: 60px;
            width: 60px;
            background-color: rgb(197, 218, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(77, 160, 255);
            border-radius: 100%;
            font-size: 27px;
            margin-bottom: 20px;
          }
          .number-of-reservation-text {
            font-size: 0.9rem;
            color: grey;
          }
          .name-of-apartment {
            font-size: 2rem;
            font-weight: 600;
          }
          .mini-data {
            margin-top: 5px;
            display: flex;
            gap: 10px;

            p {
              font-size: 0.85rem;
            }
          }
        }
        .sections-div {
          display: flex;
          flex-direction: column;
          gap: 50px;
          -webkit-animation: fade-in 0.5s ease-out 0.9s both;
          animation: fade-in 0.5s ease-out 0.9s both;
          button:not(.button-cancel-reservation) {
            width: 100%;
            height: 50px;
            background-color: rgba(166, 208, 255, 0.418);
            border-radius: 5px;
            font-weight: bolder;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: none;
            padding: 0 10px;

            svg {
              font-size: 1.2rem;
              color: rgb(66, 66, 66);
            }

            div {
              display: flex;
              gap: 10px;
              align-items: center;
              color: rgb(66, 66, 66);
            }
            &:hover {
              background-color: rgba(166, 208, 255, 0.7);
            }
          }
          button:not(.button-cancel-reservation):nth-child(1) {
            -webkit-animation: scale-in-center 0.3s 0.3s both;
            animation: scale-in-center 0.3s 0.3s both;
          }
          .cancel-reservation {
            display: flex;
            justify-content: center;
            .button-cancel-reservation {
              padding: 10px;
              background-color: $red;
              color: white;
              font-weight: bolder;
              width: fit-content;
              align-self: center;
              border-radius: 5px;

              &:disabled {
                background-color: grey;
                opacity: 0.5;
              }
            }
          }
          .canceled-reservation-info {
            margin-left: auto;
            margin-right: auto;
            padding: 10px;
            background-color: $lightRed;
            border-radius: 50px;
            font-weight: bolder;
            font-size: 15px;
            width: fit-content;
            display: flex;
            gap: 10px;
            align-items: center;

            svg {
              color: $red;
              font-size: 20px;
            }
          }
          .datas {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .info-group {
              display: flex;
              justify-content: space-between;
              gap: 20px;
              padding: 5px;

              .card-details {
                display: flex;
                gap: 20px;
                align-items: center;

                .title {
                  font-size: 0.7rem;
                }
              }

              h2 {
                display: flex;
                gap: 5px;
                align-items: center;
                font-weight: 600;
                color: rgb(0, 0, 65);
              }
            }
          }
        }
      }
    }
  }
  .loading-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 1.2rem;
      font-weight: 600;
      color: rgb(0, 0, 65);
    }
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .reservation-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      color: $red;
    }
    img {
      width: 350px;
    }
  }

  @media only screen and (max-width: 1050px) {
    .apartment-informations-container {
      width: 90% !important;
    }
    .details-container {
      flex-direction: column-reverse;
      align-items: center;
      gap: 30px;

      .reservation-details {
        width: 100%;

        .container-with-info {
          width: 80%;

          .button-cancel-reservation {
            width: 70% !important;
          }
        }
      }
    }
  }

  // ANIMATIONS

  @-webkit-keyframes tilt-in-bottom-1 {
    0% {
      -webkit-transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }
  @keyframes tilt-in-bottom-1 {
    0% {
      -webkit-transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
}
