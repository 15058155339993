@import "../../../../colors";

.card-details-container {
  display: flex;
  width: 350px;

  .form-card-details {
    animation: slide-in-blurred-bottom 0.4s cubic-bezier(0.23, 1, 0.32, 1) both;
    .card-details {
      width: 100%;
      & > div {
        display: flex;
        flex-direction: column;

        p {
          font-size: 1.1rem;
          margin-bottom: 0.2rem;
        }
        & > div {
          width: 80%;
        }
      }
    }
    .introduction {
      h1 {
        font-size: 1.8rem;
        font-weight: 500;
      }
      p {
        font-size: 0.9rem;
        color: rgb(48, 48, 48);
        font-weight: 300;
        margin-bottom: 20px;
      }
    }

    .button-payment {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $main;
      padding: 5px;
      border-radius: 10px;
      color: white;
      font-weight: bolder;
      margin-top: 15px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: $mainDarker;
      }

      &:disabled {
        background-color: grey;
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .error-message {
      color: $redError;
      font-weight: bolder;
    }

    .status-of-payment {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bolder;
      margin-top: 10px;
      h2 {
        color: $darkBlue;
        width: fit-content;
        border-bottom: 1px solid grey;
      }
      p {
        margin-top: 5px;
        color: rgb(77, 77, 77);
      }
      .warning {
        color: $redError;
        margin-bottom: 5px;
      }
    }
  }

  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      -webkit-filter: blur(20px);
      filter: blur(20px);
      opacity: 0;
    }
    100% {
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 300px) {
    width: 95%;
  }
}
