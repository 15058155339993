@import "../../../colors";

.header {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 130px;
  padding-right: 130px;
  background-color: white;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  position: sticky;
  top: 0px;
  height: 82px;
  z-index: 10;
  transition: background-color 0.5s;
  @media only screen and (max-width: 650px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .logo-icon {
    width: 250px;
  }
  .list-of-links-header {
    display: flex;
    align-items: center;
    gap: 15px;

    .error-message {
      color: $red;
      text-align: center;
      font-weight: bolder;
    }

    .links-to-appartaments {
      color: black;

      .apartment-button {
        svg {
          padding-right: 5px;
          color: $main;
        }
      }
      .selected {
        pointer-events: none;
        background-color: white;
      }
    }
    .menu-link {
      position: relative;
      color: black;
      font-size: 1rem;
      &::before {
        transition: all 0.3s;
        top: 110%;
        position: absolute;
        content: "";
        background-color: black;
        height: 2px;
        border-radius: 30px;
        width: 0px;
      }

      &:hover {
        &::before {
          width: 50%;
        }
      }
    }
    .black {
      &::before {
        background-color: black !important;
      }
      color: black;
    }
    .active {
      text-decoration: none;
      pointer-events: none;

      &::before {
        width: 50%;
      }
    }
    .can-click {
      pointer-events: all !important;
    }
    .button-reservation {
      padding: 10px;
      width: 125px;
      text-align: center;
      font-size: 15px;
      background-color: $main;
      border-radius: 20px;
    }

    .no-apartments-avilvable {
      opacity: 0.6;
    }
  }
  @media only screen and (max-width: 650px) {
    & {
      height: 62px;
    }
    .logo-icon {
      width: 200px;
    }
  }
}
.transparent {
  background-color: rgba(255, 255, 255, 0.7);
}

ul,
li {
  list-style: none;
}

.dropdown {
  display: flex;
  height: 100px;
  width: 100px;

  .button-dropdown {
    padding: 10px;
  }

  .dropdown-menu {
    background-color: black;
    height: 100px;
    width: 100px;
  }
}
