@import "../../../colors";

.full-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.815);
  z-index: 100000;
  animation: fadeIn 0.2s ease-in-out;
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    z-index: 100009999;
    color: white;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: red;
    }
  }
  .full-image {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    max-width: 90vw;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgb(36, 36, 36);
    cursor: zoom-in;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .zoomed {
    img {
      cursor: zoom-out;
      scale: 1.75;
    }
  }

  .bottom-image-select {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img,
    video {
      width: auto;
      height: auto;
      max-width: 75px;
    }

    .video-container {
      position: relative;

      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 2rem;
        cursor: pointer;
      }
    }
  }

  .buttons-group {
    position: absolute;
    width: 100%;
    top: 50%;
    padding: 0 20px;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;

    button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.815);
      color: $main;
      font-size: 30px;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:not(:disabled) {
        &:hover {
          color: $mainDarker;
        }
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .buttons-group {
      display: none;
    }
  }
}
