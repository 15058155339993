.modal-about-us-container {
  .about-us-text {
    padding: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 2;

    span {
      font-weight: bolder;
    }
  }
}
