@import "../../../colors";

.modalReservation-container {
  position: relative;
  .bg-change {
    background-color: rgb(253, 253, 253);
    border-radius: 15px;
  }
  .modal-body {
    min-height: 200px;
    .main-modal-body {
      border-top: 2px solid $darkBlue;
      margin-top: 30px;
      padding-top: 10px;
      min-height: 482px;
      position: relative;

      .data-about-resevation {
        display: flex;
        justify-content: space-between;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        p {
          display: flex;
          flex-direction: column;
          .price,
          .date {
            font-weight: 700;
          }
        }
        .price-of-reservation {
          & > div {
            display: flex;
            gap: 10px;
          }
          .discount-activate {
            position: relative;
            color: grey;
            &::before {
              content: "";
              width: 100%;
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
              height: 2px;
              background-color: $red;
            }
          }
          .price {
            display: flex;
            .additional-payment {
              margin-left: 10px;
              gap: 5px;
              display: flex;
              flex-direction: row;
              color: grey;
            }
          }
        }

        @media only screen and (max-width: 522px) {
          flex-direction: column;
          justify-content: start;
          align-items: center;
        }
        @media only screen and (max-width: 430px) {
          width: 80%;
        }
      }
    }
  }
  .modal-footer {
    display: flex;
    gap: 20px;

    button {
      padding: 10px;
      color: white;
      border-radius: 10px;
    }
    .close-button {
      background-color: $red;
    }
    .next-step-button {
      background-color: $main;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-color: $mainDarker;
      }
    }
  }
  .progres-bar {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: content-box;
    .button-down {
      position: absolute;
      top: 50%;
      left: -30%;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      transform: translate(-10%, -50%);
      border-radius: 100%;
      transition: background-color 0.3s ease-in;

      &:hover {
        background-color: rgba(82, 82, 82, 0.226);
      }
    }
    .line {
      height: 2px;
      background-color: rgb(48, 48, 48);
      width: 100%;
      display: flex;
      .status-bar {
        height: 2px;
        background-color: $main;
      }
    }
    .element {
      position: relative;
      .circle-icon {
        position: relative;
        height: 40px;
        width: 40px;
        background-color: $main;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        transition: all 0.2s;
        color: white;
      }
      .success {
        background-color: $green;
        color: white;
      }
      .element-text {
        user-select: none;
        position: absolute;
        width: max-content;
        top: 50px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
