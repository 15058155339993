@import "../../../colors";

.carousel-by-me {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 1100px) {
    .main-image {
      max-width: 100dvw !important;
      height: 54dvw !important;
    }
    .mineture {
      max-width: 100dvw !important;
      .opro {
        height: 12dvw !important;
        img {
          width: 100vw !important;
          height: inherit !important;
        }
      }
    }
  }
  .main-image {
    border-radius: 10px;
    transition: background-image 0.5s;
    background-size: cover;
    background-position: center;
    height: 600px;
    max-width: 1100px;
    position: relative;
    img {
      height: inherit;
      width: inherit;
    }

    &:hover {
      .hide {
        opacity: 1 !important;
      }
    }

    display: flex;

    .buttons {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-self: center;
      justify-content: space-between;
      z-index: 2;

      .hide {
        opacity: 0;
        transition: opacity 0.3s;
      }

      .show {
        opacity: 1;
      }

      button {
        border: none;
        height: 50px;
        width: 50px;
        font-size: 30px;
        font-weight: 100;
        background-color: rgba(41, 41, 41, 0.89);
        color: white;
        margin: 0;
        padding: 0;
      }
    }
  }

  .mineture {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    max-width: 1100px;
    overflow: auto;
    padding: 10px;

    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      width: 5px;
    }

    &::-webkit-scrollbar {
      height: 5px;
      padding-top: 5px;
    }

    .opro {
      height: 100px;
      min-width: 200px;
      cursor: pointer;
      user-select: none;
      border-radius: 10px;

      img {
        border-radius: 10px;
        height: 100px;
        width: 200px;
      }
    }

    .current {
      outline: 4px solid $main;
      pointer-events: none !important;
    }
  }
}
