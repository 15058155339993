@import "../../../../colors";

.montly-prices-div {
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .montly-prices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .info-important {
    color: black;
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    margin-top: 20px;

    span {
      font-weight: bolder;
      color: $mainDarker;
    }
  }
}
