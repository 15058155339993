.basic-informations-container {
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .info div {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .topic {
      font-weight: bolder;
      color: grey;
      margin-right: 10px;
    }
    .value {
      width: 50%;
      font-weight: bolder;
      color: black;
    }
  }
}
