@import "../../../colors";

.menu-hamburger-container {
  height: fit-content;
  color: black;

  .menu-box {
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 2;
    height: auto;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    padding-bottom: 20px;

    .items-list {
      width: 100%;
      animation: fade-in 0.3s both;
      animation-delay: 0.5s;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
      gap: 10px;
      .apartments-container {
        width: 60%;
        padding: 5px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 5px;
      }
    }

    .active {
      font-weight: bolder;
    }

    .button-reservation {
      padding: 10px;
      border-radius: 10px;
      background-color: $main;
      color: white;
      font-weight: bold;
      width: 60%;
      text-align: center;
    }
    @media only screen and (max-width: 650px) {
      & {
        top: 60px;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
