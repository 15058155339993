@import "../../../../../colors";

.page-first-container {
  margin-bottom: 10px;
  .text-about-page {
    color: $grey;
    font-weight: 200;
    margin-bottom: 10px;
  }
  .input-group {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    .flex-inputs {
      display: flex;
      justify-content: space-between;
    }
    .flex-div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    p {
      color: $grey;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    input:not(.flex-input) {
      width: 100%;
    }
    .personal-info {
      text-transform: capitalize;
    }
    input {
      margin-top: 5px;
      border-radius: 10px;
      padding: 10px;
      border: 1px solid;
      border-color: $lightGrey;
      transition: all 0.5;
      &:focus {
        border-color: $main;
        outline: 1px solid $main;
        -webkit-box-shadow: 0px 0px 36px -26px $main;
        -moz-box-shadow: 0px 0px 36px -26px $main;
        box-shadow: 0px 0px 36px -26px $main;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .input-group {
      .flex-inputs {
        flex-direction: column;
        input {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 310px) {
    .text-about-page {
      font-size: 0.9rem;
    }
  }
}
