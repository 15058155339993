@import "../../colors";

.galery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px;
  margin: 0 auto;
  scroll-behavior: smooth;

  .galery-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    h1 {
      font-size: 3rem;
      margin-bottom: 20px;
      font-weight: bolder;
      border-bottom: 3px solid $main;
      -webkit-animation: tracking-in-expand 0.5s
        cubic-bezier(0.215, 0.61, 0.355, 1) both;
      animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
        both;
    }
    p {
      line-height: 2;
      font-size: 1.2rem;
    }
  }
  .categories-list {
    display: flex;
    gap: 150px;
    flex-direction: column;

    .category {
      -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
      animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      h2 {
        font-size: 1.75rem;
        margin-bottom: 20px;
        font-weight: bolder;
        border-bottom: 3px solid $main;
      }
      .images {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;

        img {
          width: 250px;
          height: 250px;
          object-fit: cover;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    @media (max-width: 768px) {
      .category {
        .images {
          img {
            width: 30% !important;
            height: 150px !important;
          }
        }
      }
    }
  }

  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}
