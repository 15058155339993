@import "../../colors";

.checkout-container {
  background: url("../../assets/images/wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 98.5vh;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    margin-right: auto;
    width: 1200px;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    .right-side {
      padding: 10px;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        max-width: 500px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(112, 168, 182, 1);
        -moz-box-shadow: 0px 0px 20px -5px rgba(112, 168, 182, 1);
        box-shadow: 0px 0px 20px -5px rgba(112, 168, 182, 1);
        border-radius: 15px;
        .box-header {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        .box-content-apartment-image {
          display: flex;
          justify-content: center;
          margin: 20px auto;
          width: 425px;
          height: 250px;
          img {
            max-width: 425px;
            max-height: 250px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
          }
        }
        .box-content {
          width: 100%;
          .box-content-apartment {
            .apartment-informations {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .info-group {
                display: flex;
                justify-content: space-between;
                border-radius: 5px;

                h3 {
                  font-weight: bolder;
                }

                a {
                  text-decoration: underline;
                  text-underline-offset: 5px;
                  transition: all 0.2s ease-in-out;
                  &:hover {
                    text-decoration-color: $mainDarker;
                  }
                  word-wrap: normal;
                }

                .price {
                  display: flex;
                  gap: 10px;

                  .discount-price {
                    position: relative;
                    color: grey;

                    &::before {
                      content: "";
                      position: absolute;
                      top: 50%;
                      left: 0;
                      width: 100%;
                      height: 1px;
                      background-color: red;
                      transform: rotate(10deg);
                    }
                  }
                }
              }
            }

            .promo-code-div {
              border-top: 1px solid #dddddd;
              padding-top: 10px;
              margin-top: 20px;
              h2 {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 1rem;
              }
              form {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                .promo-code-input {
                  border: 1px solid $main;
                  border-radius: 10px;
                  transition: all 0.2s ease-in-out;
                  padding: 5px;
                  width: 100%;
                  &:disabled {
                    background-color: #ffffff;
                    border: 1px solid $green;
                    opacity: 0.6;
                    box-shadow: 0px 0px 20px -7px $green;
                    cursor: not-allowed;
                  }
                  &:focus {
                    outline: none;
                  }
                }
                button {
                  width: 100px;
                  padding: 10px;
                  border-radius: 5px;
                  border: none;
                  background-color: $main;
                  color: white;
                  cursor: pointer;
                  transition: all 0.2s ease-in-out;
                  &:hover {
                    background-color: rgb(34, 34, 34);
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    @-webkit-keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @media only screen and (max-width: 1200px) {
      width: 100% !important;
      justify-content: center;
      gap: 30px;
    }

    @media only screen and (max-width: 800px) {
      justify-content: center;
      gap: 30px;
    }

    @media only screen and (max-width: 475px) {
      justify-content: center;
      .left-side {
        width: 80%;
      }
      .right-side {
        width: 100%;
        .box {
          font-size: 0.9rem;
          .box-content-apartment-image {
            width: 85vw;
            height: 50vw;
            justify-content: center;
            img {
              width: 85vw;
              height: 50vw;
            }
          }
        }
      }
    }
  }
}
