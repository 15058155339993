.modal-contact-container {
  .modal-body {
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    a {
      display: flex;
      gap: 20px;
    }

    .phone {
      svg {
        color: rgb(0, 192, 0);
      }
    }
    .email {
      svg {
        color: rgb(85, 85, 172);
      }
    }
  }
}
