@import "../../../../../colors";
.payment-container {
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
.error-messages-div {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .error-card-message {
    background-color: $redError;
    border: 2px solid #c70101;
    padding: 10px;
    width: 100%;
    text-align: center;
    font-weight: 900;
    color: white;
  }
}
.card-informations {
  display: flex;
  width: 100%;
  flex-direction: column;

  .card-number {
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    .primary {
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.5px;
    }
    .mini-text {
      color: rgba(94, 94, 94, 0.726);
      margin-bottom: 10px;
    }
  }
  .promocode-div {
    padding-left: 10px;
    .title {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.3px;
      margin-bottom: 5px;
    }
    input {
      border-radius: 10px;
      border: 2px solid $main;
      padding-left: 5px;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;

      &:focus {
        outline: 1px solid $main;
      }
    }
    .success-input {
      border-color: rgba(0, 128, 0, 0.6);
      pointer-events: none;
      opacity: 0.6;
    }
    .error-input {
      border-color: $redError;
    }
  }
  .submit-card-payment {
    width: 200px;
    background-color: rgb(4, 4, 4);
    color: white;
    height: 50px;
    margin-top: 10px;
    font-size: 20px;
    border-radius: 20px;
    font-weight: 600;
    svg {
      padding-left: 10px;
    }
    margin-left: auto;
    margin-right: auto;

    &:disabled {
      opacity: 0.5;
    }
  }
}
