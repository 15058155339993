.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: red;
  gap: 5px;

  .pin-icon {
    font-size: 2rem;
  }

  .pin-text {
    font-size: 1px;
    width: max-content;
  }
}
