.facilities-container {
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .facilities-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    .facilitie {
      display: flex;
      width: fit-content;
      gap: 10px;
      text-align: left;
      align-items: center;

      svg {
        color: rgb(0, 190, 0);
      }
    }
    @media only screen and (max-width: 440px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 300px) {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
    }
  }
}
