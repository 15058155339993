@import "../../colors";

.find-my-reservation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .information {
    text-align: center;
    margin-bottom: 20px;
    h1 {
      margin: 0;
      font-size: 30px;
      font-weight: 600;
    }
  }
  .find-my-reservation-form {
    display: flex;
    gap: 20px;

    input {
      width: 300px;
      height: 40px;
      border: 1px solid grey;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 16px;
      &:focus {
        outline: none;
        border: 1px solid $main;
      }
    }

    button {
      width: 100px;
      height: 40px;
      border: none;
      border-radius: 5px;
      background-color: $main;
      color: white;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: $mainDarker;
      }

      &:active {
        scale: 0.9;
      }
    }
  }

  @media only screen and (max-width: 440px) {
    .find-my-reservation-form {
      input {
        width: 100%;
      }
    }
  }
  // ANIMATIONS

  @-webkit-keyframes tilt-in-bottom-1 {
    0% {
      -webkit-transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }
  @keyframes tilt-in-bottom-1 {
    0% {
      -webkit-transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      transform: rotateY(30deg) translateY(300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }
}
